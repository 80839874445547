
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import ApiBanner from "@/request/apis/api_banner";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";

    interface IState {
        searches:any,
        total:any,
        list:any,
        visible:any,
        form:any,
        title:any,
        name:any,
        type:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize
                },
                total:0,
                list:[],
                activeKey:1,
                visible:false,
                name:'',
                title:'',
                form:{
                    is_show:1,
                    name:'',
                    sort:255
                },
                type:'',
            })
            const rules = {
                name: [{required: true, message: "请输入单位名称"},],
                sort: [{required: true, message: "请输入排序"},],
                is_show: [{required: true, message: "请选择是否显示"},],
            }
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center',
                    width: 100
                },
                {
                    title: '单位名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center',
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align:'center',
                },
                {
                    title: '显示状态',
                    dataIndex: 'is_show',
                    key: 'is_show',
                    align:'center',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250,
                    align:'center',
                },
            ];

            const getList = async() => {
                const params = {...state.searches}
                const res = await ApiUser.unitLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                }
            }




            const onDel = async(val) =>{
                const params = {id: val.id}
                const res = await ApiUser.unitDel(params)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    getList()
                }else{
                    message.error(res.msg)
                }
            }

            const edit = (val) =>{
                state.title = '编辑商品单位'
                state.form.name = val.name
                state.form.sort = val.sort
                state.form.is_show = val.is_show
                state.form.id = val.id
                state.type = 2
                state.visible = true

            }

            const formRef = ref();
            const handleOk = async() => {
                formRef.value.validate().then(async () => {
                        const params = {...state.form}
                        if (state.type == 1) {
                            const res = await ApiUser.unitAdd(params)
                            if (res.error_code == 0) {
                                message.success(res.msg)
                                state.visible = false
                                getList()
                            }else{
                                message.error(res.msg)
                            }

                        } else {
                            const res = await ApiUser.unitEdit(params)
                            if (res.error_code == 0) {
                                message.success(res.msg)
                                state.visible = false
                                getList()
                            }else{
                                message.error(res.msg)
                            }
                        }
                    })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const add = (val) => {
                state.visible = true
                state.type = 1
                state.form={
                    is_show:1,
                    name:'',
                    sort:255
                }
                state.title='新增商品单位'
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                pagination,
                handleTableChange,
                formRef,
                handleOk,
                edit,
                add,
                rules,
                columns,
                onDel
            }
        }
    });
